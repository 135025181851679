.loginPage {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.loginPage .formContainer {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 16px;
    border-radius: 8px;
    text-align: center;
}

.loginLogo {
    position: fixed;
    bottom: 0;
    right: 0;
}